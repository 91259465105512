<template>
  <div>
    <div :style="'background-image: url(' + loginBg + ');'" class="login-container">
      <div class="login-main-wrapper">
        <div class="login-main-container">
          <div class="login-form-wrap">
            <div class="form-title">
              <img src="../assets/img/login/login-header.png" class="login-header-pic">
            </div>
            <div class="form-title sub">
              户用光伏开发管理平台
            </div>
            <el-form :model="loginForm" :rules="loginRules" class="login-form" label-positin="left" label-width="0px"
                     ref="loginRef">
              <el-form-item prop="username">
                <el-input :autofocus="true" auto-complete="off" placeholder="账户"
                          prefix-icon="el-icon-user" type="text" v-model="loginForm.username"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input @keyup.native.13="loginFormSubmit('loginRef')" auto-complete="off" placeholder="密码"
                          prefix-icon="el-icon-lock" type="password" v-model="loginForm.password"></el-input>
              </el-form-item>
              <el-form-item prop="remember" style="font-size: 14px;margin: 0 0 15px 0;">
                <el-checkbox v-model="loginForm.remember">自动登录</el-checkbox>
                <!--              <a style="float: right;color: #0080FF ;" href="#">忘记密码</a>-->
              </el-form-item>
              <el-form-item class="submit-item">
                <el-button :loading="logining" @click.native.prevent="loginFormSubmit('loginRef')"
                           style="width: 100%;" type="primary">登录
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer"></div>
    <div class="login-header">
      户用光伏开发管理平台
    </div>
  </div>
</template>

<script>
import {login} from "@/api/auth"
import {APP_NAME} from "@/utils/config"

export default {
  name: "Login",
  data() {
    return {
      loginBg: require('@/assets/img/login/login-background.png'),
      app_name: APP_NAME,
      logining: false,
      loginForm: {
        username: '',
        password: '',
        remember: false,
      },
      loginRules: {
        username: [
          {required: true, message: '请输入账户', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      },
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    loginFormSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          console.log("submit error")
          return false
        }
        that.logining = true
        login(that.loginForm.username, that.loginForm.password).then(res => {
          // console.log(res);
          that.logining = false
          that.$store.dispatch('auth/login', {authorization: res.token, user: res.user})
          that.$router.push({path: res.user.firstMenu})
        }).catch(err => {
          that.logining = false
          console.log(err)
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  .login-main-wrapper {
    .login-main-container {
      width: 430px;
      height: 100vh;
      margin: auto;
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: white;

      .login-form-wrap {
        width: 410px;
        height: 600px;
        padding: 0 20px;

        .form-title {
          display: flex;
          justify-content: flex-start;

          .login-header-pic {
            width: 200px;
            height: auto;
          }
        }

        .form-title.sub {
          justify-content: flex-end;
          color: #666;
          padding-bottom: 40px;
          font-size: 14px;
        }


        .login-form {
          /deep/ .el-input__prefix {
            color: #409eff !important;
            font-weight: bold !important;
          }
        }
      }

      @media only screen and (max-width: 810px) {
        & {
          width: 100vw;
          min-height: 410px;
        }
        .login-welcome {
          width: calc(100% - 410px);
          transition: width .45s linear;
        }
      }
    }
  }
}

.login-header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 40px;
  background-color: #141414;
  color: #FFFFFF;
  line-height: 40px;
  padding-left: 30px;
  font-size: 14px;
}

.login-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 40px;
  background-color: #141414;
}
</style>
